.card {
    // .card__content
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        position: relative;

        @media (max-width: $md2) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            padding-bottom: 25px;

            border-bottom: 1px solid #e5e7eb;
        }

        &-cart {
            @media (max-width: 1200px) {
                flex-direction: column;
            }
        }
    }
    // .card__left
    &__left {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: $md2) {
            margin-bottom: 10px;
        }
    }
    // .card__img
    &__img {
        width: 110px;
        height: auto;

        object-fit: cover;
    }
    // .card__left-bottom
    &__left-bottom {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media (max-width: $md2) {
            height: 100%;
        }
    }
    &__new-taste {
        color: #fff;

        font-size: 14px;
        font-weight: 500;
        line-height: 14px; /* 100% */
        text-transform: uppercase;

        width: 48px;
        height: 20px;
        padding: 4px 8px;

        border-radius: 100px;
        background: #f472b6;
    }
    // .card__title
    &__title {
        color: #111827;

        font-size: 22px;
        font-weight: 400;
        line-height: 26px;
        text-transform: uppercase;

        @media (max-width: $md2) {
            font-size: 16px;
        }
    }
    // .card__subtitle
    &__subtitle {
        color: #6b7280;

        font-size: 22px;
        font-weight: 400; /* 118.182% */

        @media (max-width: $md2) {
            font-size: 16px;
        }
    }
    // .card__center
    &__center {
        position: absolute;
        left: 60%;
        @media (max-width: $md2) {
            top: 120px;
            left: 0%;
        }

        &-cart {
            position: absolute;
            left: auto;
            right: 6%;

            @media (max-width: $md2) {
                right: auto;
            }
        }
    }
    // .card__price
    &__price {
        color: #111827;
        text-align: right;
        font-size: 22px;
        font-weight: 600;
        line-height: 28px; /* 127.273% */
        margin-bottom: 8px;

        @media (max-width: $md2) {
            text-align: start;
        }
    }
    // .card__totalprice
    &__totalprice {
        color: #6b7280;
        text-align: right;

        font-size: 16px;
        font-weight: 400;
        line-height: 22px; /* 137.5% */

        @media (max-width: $md2) {
            text-align: start;
        }
    }
    // .card__right
    &__right {
        position: relative;

        @media (max-width: $md2) {
            align-self: flex-end;
        }
        &-cart {
            flex: 0 0 150px;

            @media (max-width: $md2) {
                flex: 0 0 auto;
            }
        }

        .counter {
            position: absolute;

            right: 0;
            top: 0;
            transform: translateY(-50%);

            @media (max-width: $md2) {
                position: relative;
                transform: translateY(0%);
            }
        }
    }
    &__cart-remove {
        cursor: pointer;

        @media (max-width: $md2) {
            position: absolute;
            bottom: 24px;
            transform: translateY(-50%);
            right: 200px;
        }
    }
}
