.taste-wrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 30px;

    @media (max-width: $md1) {
        gap: 20px;
    }
}

.taste {
    // .taste__content
    &__content {
    }
    // .taste__title
    &__title {
        width: 100%;
        padding: 20px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        margin-bottom: 20px;

        font-size: 22px;
        font-weight: 700;
        line-height: 26px; /* 118.182% */
        border-radius: 8px;
        background: #fff7ed;

        @media (max-width: $md1) {
            justify-content: space-between;
        }

        svg {
            /*display: none;

            @media (max-width: $md1) {
                display: block;
            }*/
            transform: rotate(180deg);
        }

        &._active {
            svg {
                transform: rotate(0deg);
            }
        }

        // ._rose
        &._rose {
            background: #fdf2f8;
            span {
                color: #f472b6;
            }
        }
        // ._orange
        &._orange {
            background: #fff7ed;
            span {
                color: #fb923c;
            }
        }
        // ._blue
        &._blue {
            background: #f0f9ff;

            span {
                color: #38bdf8;
            }
        }
    }
    // .taste__body
    &__body {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    // .taste__card
    &__card {
    }
}
