.catalog {
    // .catalog__content
    &__content {
    }
    // .catalog__button
    &__button {
        height: 44px;
        padding: 10px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-radius: 8px;
        background: #f3f4f6;
        transition: 0.25s ease-in-out all;

        color: #111827;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;

        margin-bottom: 4px;

        &:hover {
            background: #fff;
        }

        &._active {
            background: #fff;
        }
    }
    // .catalog__body
    &__body {
        transform: translateY(0%);
        transition: 0.25s ease-in-out all;
        position: absolute;
        z-index: 10;

        padding: 4px;

        border-radius: 8px;
        background: #fff;
        box-shadow:
            0px 10px 10px -5px rgba(45, 77, 108, 0.04),
            0px 20px 25px -5px rgba(45, 77, 108, 0.1);

        &._hide {
            opacity: 0;
            z-index: -1;
            transform: translateY(-3%);
        }
    }
    // .catalog__list
    &__list {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    // .catalog__item
    &__item {
        padding: 9px 20px 9px 16px;
        gap: 8px;

        transition: 0.15s ease-in-out all;
        &._active {
            border-radius: 8px;
            background: #f3f4f6;
        }
    }
    // .catalog__link
    &__link {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }
}
