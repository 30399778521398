.button {
    display: inline-block;
    background-color: #1f2937;
    text-align: center;
    cursor: pointer;
    transition: 0.25s ease-in-out all;
    @include noselect();
    border: none;
    cursor: pointer;

    &-plus {
        padding: 10px;
        display: flex;

        justify-content: center;
        align-items: center;

        border-radius: 8px;
        background: #1f2937;

        width: 38px;
        height: 38px;

        &-sm {
            width: 38px;
            height: 38px;
        }
        &-md {
            width: 48px;
            height: 48px;
        }

        &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #111827;
        }

        &:disabled {
            background: #e5e7eb;

            & svg {
                fill: #9ca3af;
            }
        }
    }

    &-minus {
        padding: 10px;
        display: flex;

        justify-content: center;
        align-items: center;

        border-radius: 8px;
        background: #f3f4f6;

        width: 38px;
        height: 38px;

        &-sm {
            width: 38px;
            height: 38px;
        }
        &-md {
            width: 48px;
            height: 48px;
        }

        &:hover {
            background: #e5e7eb;
        }
    }

    &-checkout {
        display: flex;
        height: 44px;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;

        font-family: "Roboto";
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;

        background-color: #1f2937;
        border-radius: 8px;
        color: #fff;

        &:hover {
            background-color: #111827;
        }

        &:disabled {
            background-color: #e5e7eb;
            color: #9ca3af;
            cursor: disabled;
        }
    }
}

.counter {
    height: 50px;

    border-radius: 8px;
    border: 1px solid #e5e7eb;
    background: #fff;
    overflow: hidden;
    &__content {
        display: flex;
        align-items: center;
    }
    &__body {
        padding: 0 33px;
        display: inline;
        width: auto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
    }

    &-sm {
        height: 38px;
        width: 150px;
    }
    &-md {
        height: 50px;
        width: 172px;
    }
}
