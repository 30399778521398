.aroma {
    &__container {
        display: flex;
        gap: 2rem;
        // margin-right: 4rem;
        
        @media (orientation: portrait)
        {
            display: block;
        }
    }
    
    &__title {
        // padding-right: 4rem;
        color: #535353;

        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        // text-transform: uppercase;

        @media (max-width: $md2) {
            font-size: 16px;
        }
    }

    &__header {
        color: #6b7280;
    }
}
