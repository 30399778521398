.checkout {
    // .checkout__content
    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
    }
    // .checkout__form
    &__form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 498px;
        width: 100%;
    }
    // .checkout__label
    &__label {
        display: flex;
        flex-direction: column;
        gap: 10px;

        font-family: Roboto;
        color: #6b7280;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px; /* 137.5% */
    }
    // .checkout__input
    &__input {
        padding: 11px;
        width: 100%;
        align-items: center;

        border-radius: 10px;
        border: 1px solid var(--Grey-200, #e5e7eb);
        background: var(--Gray-50, #f9fafb);
        // ._company
        &._company {
        }
        // ._tel
        &._tel {
        }
    }
    // .checkout__textarea
    &__textarea {
        height: 98px;

        padding: 11px;
        border-radius: 10px;
        border: 1px solid #e5e7eb;
        background: #f9fafb;

        font-family: Roboto;
        color: #6b7280;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
    }
    // .checkout__body
    &__body {
        display: flex;
        flex-direction: column;
        align-self: flex-start;

        max-width: 423px;
        width: 100%;
        height: auto;
        padding: 20px;

        border-radius: 8px;
        background: #f9fafb;
    }
    // .checkout__body-header
    &__body-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 18px;
    }
    // .checkout__body-title
    &__body-title {
        color: #6b7280;

        font-size: 16px;
        font-weight: 500;
        line-height: 22px; /* 137.5% */
    }
    // .checkout__body-subtitle
    &__body-subtitle {
        color: #6b7280;

        /* S Normal */
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }
    &__images-container {
        position: relative;
    }
    &__images-count {
        position: absolute;
        right: 3px;
        top: 3px;
        border-radius: 50%;
        background-color: #1f2937;
        color: #fff;
        font-size: 9px;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    // .checkout__images
    &__images {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;

        margin-bottom: 18px;
    }
    // .checkout__images-item
    &__images-item {
        width: 48px;
        height: auto;
        object-fit: cover;
    }
    // .checkout__price
    &__price {
        color: #111827;

        font-size: 22px;
        font-weight: 600;
        line-height: 28px; /* 127.273% */
        margin-bottom: 18px;
    }
    // .checkout__confirm
    &__confirm {
        margin-bottom: 6px;
    }
    // .checkout__confirm-text
    &__confirm-text {
        color: #6b7280;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
    }
}
