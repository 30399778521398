.cart {
    // .cart__content
    &__content {
        display: flex;
        gap: 20px;

        @media (max-width: $md2) {
            flex-direction: column;
        }
    }
    // .cart__items
    &__items {
        flex: 1 0 auto;
    }
    // .cart__card
    &__card {
    }
    // .cart__right
    &__right {
        display: flex;
        flex-direction: column;
        flex: 0 0 239px;
        height: auto;
        align-self: flex-start;

        padding: 10px;
        border-radius: 8px;
        background: #f9fafb;

        @media (max-width: $md2) {
            flex: 0 0 auto;
            align-self: center;
        }
    }
    // .cart__right-totalCount
    &__right-totalCount {
        margin-bottom: 7px;

        color: #6b7280;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }
    // .cart__right-sum
    &__right-sum {
        margin-bottom: 18px;

        color: #111827;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px; /* 127.273% */
    }
    // .cart__right-text
    &__right-text {
        margin-top: 6px;

        color: #db2777;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
    }
}
