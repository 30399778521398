.nav {
    margin-top: 20px;

    @media (max-width: $md1) {
        display: none;
    }
    // .nav__content
    &__content {
        display: flex;
        gap: 15px;
        align-items: center;
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    // .nav__item
    &__item {
        color: #9ca3af;
        position: relative;

        &::before {
            content: "/";
            position: absolute;
            left: -10px;
        }

        // ._main
        &._main {
            color: #6b7280;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */

            &::before {
                content: "";
            }
        }
    }
}
