.header {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #111827;
	min-height: 128px;

	&._mob {
		display: none;

		@media (max-width: 991.98px) {
			display: block;
		}
	}

	&._desc {
		display: block;

		@media (max-width: 991.98px) {
			display: none;
		}
	}

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 15px;
		padding: 20px 0px;
        margin-top: 0 !important;
		height: 128px;

		@media (max-width: 991.98px) {
			flex-direction: column;
			justify-content: center;
			padding: 20px !important;
			height: 160px;
		}
	}
	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	&__logo {
		cursor: pointer;

		@media (max-width: $md1) {
			width: 70px;
			height: 56px;
		}
	}
	&__cart {
		cursor: pointer;
	}
	&__cart-content {
		display: flex;
		align-items: center;
		gap: 10px;

		@media (max-width: $md1) {
			flex-direction: column;
			gap: 5px;
		}

		svg {
			@media (max-width: $md1) {
				width: 26px;
				height: 26px;
			}
		}
	}
	&__cart-search._mob {
		display: none;

		@media (max-width: 991.89px) {
			display: block;
		}
	}
	&__cart-search._desc {
		display: block;

		@media (max-width: 991.89px) {
			display: none;
		}
	}
	// .header__cart-body
	&__cart-body {
		display: flex;
		flex-direction: column;

		& > span {
			color: #fff;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 22px;

			@media (max-width: $md1) {
				text-align: center;
				font-size: 14px;
			}
		}

		div {
			color: #9ca3af;

			/* S Normal */
			font-family: Roboto;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px; /* 128.571% */
		}

		div > span {
			color: #9ca3af;
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
		}
	}
}
