.search-bar {
    max-width: 695px;
    width: 100%;
    // .search-bar__content
    &__content {
        position: relative;
    }
    // .search-bar__input
    &__input {
        width: 100%;
        border-radius: 10px;
        border: 1px solid #6b7280;
        padding: 11px;
        background: transparent;
        padding-left: 38px;

        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px; /* 137.5% */

        &::placeholder {
            color: #fff;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
        }
    }
    // .search-bar__body
    &__body {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-left: 11px;

        display: flex;
        align-items: center;
        gap: 8px;
    }

    span {
        color: #fff;

        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
    }
}
